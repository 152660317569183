<template>
  <div class="child-page-div">
    <div class="page-content-div">
      <table-box ref="tableBox" tableTitle="成员信息" :tableFieldNum="tableFieldNum" :action="action" :params="queryParams" :reloadData.sync="reloadData">
        <template #table-head>
          <th v-for="(item, index) in tableField" v-show="item.state" :key="index">{{ item.alias }}</th>
          <th class="operation-th">操作</th>
        </template>
        <template #table-body="props">
          <tr>
            <template v-for="(item, index) in tableField">
              <td v-if="item.isHtml" :key="index" v-show="item.state" v-html="item.value(props.item, item.name)" />
              <td v-else :key="index" v-show="item.state">{{ item.value(props.item, item.name) }}</td>
            </template>
            <td>
              <div class="operation-btn-div">
                <span class="cursor-el" @click="onShowItem(props.item)">查看</span>
              </div>
            </td>
          </tr>
        </template>
      </table-box>
    </div>
  </div>
</template>

<script>
import TableBox from "../components/TableBox";
import { getFirmMembers } from "../common/api";
import FirmMemberMsgLayer from "./layer/FirmMemberMsgLayer";

export default {
  name: "Members",
  components: { TableBox },
  data() {
    return {
      action: getFirmMembers,
      reloadData: false,
      queryParams: {
        type: 0,
        msg: "",
        pageSize: 20
      },
      tableField: [
        { state: true, alias: "编号", name: "id", title: "id", isHtml: false, value: this.getObjValue },
        { state: true, alias: "姓名", name: "name", title: "name", isHtml: false, value: this.getObjValue },
        { state: true, alias: "商会标识", name: "typeName", title: "typeName", isHtml: false, value: this.getObjValue },
        { state: true, alias: "时间", name: "createTime", title: "createTime", isHtml: false, value: this.getObjValue }
      ]
    };
  },
  computed: {
    tableFieldNum() {
      let num = 1;
      this.tableField.forEach(function(value) {
        if (value.state) {
          ++num;
        }
      });
      return num;
    }
  },
  methods: {
    getObjValue(item, field) {
      return item[field];
    },
    onReloadData() {
      this.reloadData = true;
    },
    onShowItem(item) {
      this.$layer.iframe({
        content: {
          content: FirmMemberMsgLayer,
          parent: this,
          data: {
            memberInfo: item,
            isApply: false,
            refreshData: this.onReloadData
          }
        },
        area: ["600px", "600px"],
        title: "成员详情"
      });
    }
  }
}
</script>

<style lang="less" scoped>
.child-page-div {
  width: 100%;
  .page-content-div {
    border-radius: 5px;
    background-color: #f7f7fa;
    padding: 30px 20px 10px 20px;
    .operation-th {
      width: 80px;
    }
    .operation-btn-div {
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>